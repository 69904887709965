import React from 'react';
import { RichText } from 'prismic-reactjs';

import styles from './TextImage.module.scss';

const TextImage = (props) => {
    return(
        <section className={`${styles.sectionHolder}  ${props.odd ? styles.odd : ''}`}>
            <img src={props.image.url} alt={props.image.alt} />
            <div>
                <h2>{props.title}</h2>
                <RichText render={props.excerpt} />
                <ul>
                    {
                        props.list.map((list) => <li>{list.text}</li>)
                    }
                </ul>
            </div>
        </section>
    );
}

export default TextImage;