import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom';
import { client } from '../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs';
import Lottie from 'react-lottie';
import gsap from 'gsap';
import SplitText from "gsap/SplitText";
import ScrollTrigger from 'gsap/ScrollTrigger';

import styles from './Homepage.module.scss';
import {ReactComponent as Play} from '../../assets/svg/play.svg';
import {ReactComponent as Star} from '../../assets/svg/star.svg';
import {ReactComponent as Next} from '../../assets/svg/next.svg';
import {ReactComponent as Tick} from '../../assets/svg/tick.svg';
import Button from '../../components/UI/Button/Button';

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
    const [prismicData, setPrismicData] = useState({homepage: null, companies: null, ratings: null, news: null});
    const [sent, setSent] = useState({sent: false});

    useEffect(() => {
        window.scrollTo(0,0)
        const fetchPrismicData = async () => {
            try {
                const homepage = await client.query([
                    Prismic.Predicates.at('document.type', 'homepage')
                ], 
                { orderings : '[document.first_publication_date]' });

                const companies = await client.query([
                    Prismic.Predicates.at('document.type', 'companies')
                ], 
                { orderings : '[document.first_publication_date]' });

                const ratings = await client.query([
                    Prismic.Predicates.at('document.type', 'ratings')
                ], 
                { orderings : '[document.first_publication_date]' });

                const news = await client.query([
                    Prismic.Predicates.at('document.type', 'news')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({homepage: homepage.results[0], companies: companies.results, ratings: ratings.results, news: news.results});

                setupGSAP();
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const homepageReady = prismicData.homepage;
    const companiesReady = prismicData.companies;
    const ratingsReady = prismicData.ratings;
    const newsReady = prismicData.news;

    const stars = [1, 2, 3, 4, 5];

    const playVideo = e => {
        document.querySelector(`.${styles.iframePlayer}`).classList.add(styles.open);
    }

    const closeVideo = e => {
        document.querySelector(`.${styles.iframePlayer}`).classList.remove(styles.open);
    }

    const setupGSAP = () => {
        let h1 = new SplitText("h1", {type:"lines, words"});
        gsap.fromTo(h1.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3})

        let allH2 = document.querySelectorAll('h2');
        allH2.forEach((el) => {
            let h2 = new SplitText(el, {type:"lines, words"});
            gsap.fromTo(h2.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3, scrollTrigger: {
                trigger: el,
                start: "top-=550 top",
                once: true
            }})
        })

        let allH3 = document.querySelectorAll('h3');
        allH3.forEach((el) => {
            let h3 = new SplitText(el, {type:"lines, words"});
            gsap.fromTo(h3.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3, scrollTrigger: {
                trigger: el,
                start: "top-=600 top",
                once: true
            }})
        })

        let allP = document.querySelectorAll(`body #root > :not(.${styles.above}) p`);
        allP.forEach((el) => {
            gsap.fromTo(el, {opacity: 0, autoAlpha: 0}, { duration: 0.6, opacity: 1, autoAlpha: 1, delay: .3,  scrollTrigger: {
                trigger: el,
                start: "top-=750 top",
                once: true
            }})
        })

        gsap.fromTo(`.${styles.comapniesHolder} img`, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.comapniesHolder}`,
            start: "top-=450 top",
            once: true
        }});

        gsap.fromTo(`.${styles.singleNews} .${styles.imageHolder}`, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.newsHolder}`,
            start: "top-=450 top",
            once: true
        }});

        gsap.fromTo(`.${styles.ratingsHolder} .${styles.rating}`, {y: "-40%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.3, scrollTrigger: {
            trigger: `.${styles.ratingsHolder}`,
            start: "top-=450 top",
            once: true
        }});

        setTimeout(() => {
            gsap.to(`footer`, { duration: 0.8, opacity: 1, autoAlpha: 1, scrollTrigger: {
                trigger: `footer`,
                start: "top-=450 top",
                once: true
            }});
        }, 500);
    }

    let indexSelected = 0;

    const moveBack = (e) =>{
        let els = document.querySelectorAll(`.${styles.newsHolder} > div.${styles.singleNews}`);
        els[indexSelected].classList.remove(styles.active);
        indexSelected--;
        els[indexSelected].classList.add(styles.active);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected) * 100}% - ${(indexSelected) * 1.9}rem))` : `translate(calc(-${(indexSelected) * 56}rem))`;
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = 'translate(0)'
            }
        });
        if(indexSelected === 0) document.querySelector(`.${styles.prev}`).classList.remove(styles.show)
        document.querySelector(`.${styles.next}`).classList.remove(styles.hide)
    }

    const moveForward = (e) =>{
        let els = document.querySelectorAll(`.${styles.newsHolder} > div.${styles.singleNews}`);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected + 1) * 100}% - ${(indexSelected + 1) * 1.9}rem))` : `translate(calc(-${(indexSelected + 1) * 56}rem))`;
        let rem2 = window.innerWidth <= 480 ? 'translate(calc(-100% - 1.9rem))' : 'translate(-56rem)';
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = rem2
            }
        });
        els[indexSelected].classList.remove(styles.active);
        indexSelected++;
        els[indexSelected].classList.add(styles.active);
        if(indexSelected + 1 === els.length) document.querySelector(`.${styles.next}`).classList.add(styles.hide)
        document.querySelector(`.${styles.prev}`).classList.add(styles.show)
    }

    const letsTalk = e => {
        window.scrollTo({top: document.getElementById('form').offsetTop, behavior: 'smooth'});
    }

    const subscribe = e => {
        if((e.type === 'keypress' && e.key === 'Enter') || e.type === 'click'){
            e.preventDefault();
            const emailInput = document.querySelector('input[name="email"]');
            const targetInput = document.querySelector('input[name="target"]:checked');

            const options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: emailInput.value,
                    retailer: targetInput.value === 'retailer' ? 'Yes' : 'No',
                    advertiser: targetInput.value === 'advertiser' ? 'Yes' : 'No',
                    simple: true
                }),
            };
                
            fetch('https://wall-i-be.herokuapp.com/upload', options).then((response) => {
                if(response.status === 200){
                    document.querySelector(`.${styles.simpleForm}`).reset();
                    document.querySelector(`.${styles.submitHolder} p`).style.opacity = 0;
                    emailInput.value = 'Your email has been sent';
                    setSent({sent: true});
                    setTimeout(() => {
                        emailInput.value = '';
                        setSent({sent: false});
                        document.querySelector(`.${styles.submitHolder} p`).style.opacity = 1;
                    }, 3000)
                }
            });
        }
    }

    return (
        <React.Fragment>
            {
                homepageReady ?
                (
                    <React.Fragment>
                        <div className={styles.above}>
                            <div className={styles.textHolder}>
                                <h1>{homepageReady.data.title1[0].text}</h1>
                                <p>{homepageReady.data.description1[0].text}</p>
                                <div className={styles.buttonsHolder}>
                                    <div className={styles.playHolder} onClick={playVideo}>
                                        <div>
                                            <Play />
                                            <img src={homepageReady.data.video_thumbnail.url} alt={homepageReady.data.video_thumbnail.alt} />
                                        </div>
                                        <p>{homepageReady.data.video_player_text[0].text}</p>
                                    </div>
                                    <Button action={letsTalk} text={homepageReady.data.start_free_trial_button_text[0].text} />
                                </div>
                            </div>
                            <div className={styles.lottieHolder}>
                                <Lottie options={
                                    {
                                        loop: true,
                                        autoplay: true, 
                                        path: homepageReady.data.lottie_file.url,
                                        rendererSettings: {}
                                    }}
                                    height={490}
                                    width={660} />
                            </div>
                            <div className={styles.buttonsHolderMobile}>
                                <div className={styles.playHolder}  onClick={playVideo}>
                                    <div>
                                        <Play />
                                        <img src={homepageReady.data.video_thumbnail.url} alt={homepageReady.data.video_thumbnail.alt} />
                                        <p>{homepageReady.data.video_player_text[0].text}</p>
                                    </div>
                                </div>
                                <Button action={letsTalk} text={homepageReady.data.start_free_trial_button_text[0].text} />
                            </div>
                        </div>
                        <section className={styles.companies}>
                            <h2>{homepageReady.data.companies_title[0].text}</h2>
                            <div className={styles.comapniesHolder}>
                                {
                                    companiesReady ?
                                    (
                                        companiesReady.map((company) => {
                                            return <img src={company.data.company_logo.url} alt={company.data.company_logo.alt} />
                                        })
                                    ) : null
                                }
                            </div>
                            <img src={homepageReady.data.break_image.url} alt={homepageReady.data.break_image.alt} />
                        </section>
                        <section className={styles.offer}>
                            <div className={styles.title}>
                                <h3>{homepageReady.data.what_we_offer_title[0].text}</h3>
                            </div>
                            <div className={styles.textHolder}>
                                <h2>{homepageReady.data.what_we_offer_main_title[0].text}</h2>
                                <RichText render={homepageReady.data.what_we_offer_description} />
                                <Button  action={letsTalk} text={homepageReady.data.trail_button_text[0].text} />
                            </div>
                        </section>
                        <div className={styles.ctasHolder}>
                            {
                                homepageReady.data.ctas.map((singleCta) => {
                                    return (
                                        <Link className={styles.cta} style={{backgroundImage: `url(${singleCta.background_image.url})`}} to={singleCta.button_url[0].text}>
                                            <div className={styles.title}><h3>{singleCta.title2[0].text}</h3></div>
                                            <h2>{singleCta.target[0].text}</h2>
                                            <p>{singleCta.description2[0].text}</p>
                                            <Link to={singleCta.button_url[0].text}>{singleCta.button_text[0].text}</Link>
                                        </Link>
                                    );
                                })
                            }
                        </div>
                        <section className={styles.ratings}>
                            <div>
                                <h3>{homepageReady.data.ratings_title[0].text}<span>{homepageReady.data.ratings_title_orange[0].text}</span></h3>
                            </div>
                            <div className={styles.ratingsHolder}>
                                {
                                    ratingsReady ?
                                    (
                                        ratingsReady.map((rating) => {
                                            return(
                                                <div className={styles.rating}>
                                                    <img src={rating.data.rating_company_logo.url} alt={rating.data.rating_company_logo.alt} />
                                                    <div>
                                                        <p className={styles.ratingSource}>{rating.data.rating_source[0].text}</p>
                                                        <p>{rating.data.rating}/{rating.data.rating_out_of}</p>
                                                        <div className={styles.starsHolder}>
                                                            {
                                                                stars.map((myStar, index) => {
                                                                    return <div className={index + 1 <= rating.data.rating ? styles.colorStar : null}><Star /></div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : null
                                }
                            </div>
                        </section>
                        <section className={styles.news}>
                            <div className={styles.textHolder}>
                                <div className={styles.title}><h3>{homepageReady.data.news_title[0].text}</h3></div>
                                <h2>{homepageReady.data.news_main_title[0].text}</h2>
                            </div>
                            <div className={styles.newsHolder}>
                                {
                                    newsReady ?
                                    (
                                        newsReady.map((singleNews) => {
                                            return (
                                                <div className={styles.singleNews}>
                                                    <div className={styles.imageHolder} style={{backgroundImage: `url(${singleNews.data.image.url})`}}></div>
                                                    <div>
                                                        <div className={styles.textHolder}>
                                                            <h3>{singleNews.data.title[0].text}</h3>
                                                            <a href={singleNews.data.news_url.url} target="_blank" rel="noreferrer">View more</a>
                                                        </div>
                                                        <p>{singleNews.data.excerpt[0].text}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : null
                                }
                            </div>
                            <div className={styles.prev} onClick={moveBack}><Next /></div>
                            <div className={styles.next} onClick={moveForward}><Next /></div>
                        </section>
                        <section className={styles.talking} id="form">
                            <div className={styles.title}><h3>{homepageReady.data.let_s_talk_title[0].text}</h3></div>
                            <RichText render={homepageReady.data.let_s_talk_main_title} />
                            <form className={styles.simpleForm}>
                                <div className={styles.radio}>
                                    <input type="radio" id="retailer" name="target" value="retailer" defaultChecked />
                                    <label htmlFor="retailer">I’m a Retailer</label>
                                    <input type="radio" id="advertiser" name="target" value="advertiser" />
                                    <label htmlFor="advertiser">I’m an Advertiser</label>
                                </div>
                                <input type="email" name="email" placeholder="Your email address" onKeyPress={subscribe} />
                                <div className={styles.submitHolder}>
                                    {
                                        sent.sent ?
                                        <div className={styles.sent}><Tick /></div> :
                                        <input type="button" value="Ok" name="submit" onClick={subscribe} />
                                    }
                                    <p>press <span>Enter</span></p>
                                </div>
                            </form>
                        </section>
                        <div className={styles.iframePlayer}>
                            <div className={styles.close} onClick={closeVideo}>
                                <div></div>
                                <div></div>
                            </div>
                            <iframe id="player" title="video" type="text/html" width="640" height="390" src={`https://www.youtube.com/embed/${homepageReady.data.video.url.substring(homepageReady.data.video.url.indexOf('?v=') + 3)}?enablejsapi=1`} frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    );
}

export default Homepage;