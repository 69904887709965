import React from 'react';
import {Link} from 'react-router-dom'

import styles from './Button.module.scss';

const Button = (props) => {
    return (
        <React.Fragment>
            {
                props.to ?
                <Link to={props.to} className={styles.button}>{props.text}</Link> :
                <button onClick={props.action} className={styles.button}>{props.text}</button>
            }
        </React.Fragment>
    );
}

export default Button;