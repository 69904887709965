import React, { useEffect } from 'react';
import {Link, NavLink} from 'react-router-dom'

import styles from './Header.module.scss';
import logo from '../../../assets/img/logo.png';

const Header = () => {
    const toggleMenu = e => {
        e.target.closest(`.${styles.logoHolder}`).classList.toggle(styles.open);
    }

    const closeMenu = e => {
        document.querySelector('footer').style.opacity = 0;
        document.querySelector(`.${styles.logoHolder}`).classList.remove(styles.open)
    }

    const closeMenuAlt = e => {
        document.querySelector(`.${styles.logoHolder}`).classList.remove(styles.open)
    }

    useEffect(() => {
        if(window.innerWidth <= 480){
            window.addEventListener("scroll", closeMenuAlt);
        }
    }, []);

    return (
        <header>
            <div className={styles.logoHolder}>
                <Link to='/'><img src={logo} alt="Wall-i logo" /></Link>
                <div className={styles.menuIcon} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                </div>
                <nav>
                    <NavLink to="/advertisers" activeClassName={styles.active} onClick={closeMenu}>For Advertisers</NavLink>
                    <NavLink to="/retailers" activeClassName={styles.active} onClick={closeMenu}>For Retailers</NavLink>
                    <NavLink to="/signup" activeClassName={styles.active} onClick={closeMenu}>Try it free</NavLink>
                </nav>
            </div>
            <div className={styles.loginActionHolder}>
                <a href="https://app.wall-i.net/">Login</a>
                <Link to="/signup">Try it free</Link>
            </div>
        </header>
    );
}

export default Header;