import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom';
import { client } from '../../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import isEmail from 'validator/lib/isEmail';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import styles from './Footer.module.scss';
import {ReactComponent as Tick} from '../../../assets/svg/tick_white.svg';

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
    const [prismicData, setPrismicData] = useState({footer: null});
    const [sent, setSent] = useState({sent: false});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const footer = await client.query([
                    Prismic.Predicates.at('document.type', 'footer')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({footer: footer.results[0]});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
        loadReCaptcha('6LczMVQbAAAAAHC2CWdERFoe_lvW4u_WawuGcrcB');
    }, []);

    const footerReady = prismicData.footer;

    const removeFooter = e => {
        console.log('here');
        if(e.target.href !== window.location.href){
            document.querySelector('footer').style.opacity = 0;
        }
    }

    const subscribe = e => {
        if((e.type === 'keypress' && e.key === 'Enter') || e.type === 'click'){
            e.preventDefault();
            const emailInput = document.querySelector('input[name="emailSubscribe"]');
            if(!isEmail(emailInput.value)){
                return;
            }

            const options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: emailInput.value,
                    newsletter: true
                }),
            };
                
            fetch('https://wall-i-be.herokuapp.com/upload', options).then((response) => {
                if(response.status === 200){
                    document.querySelector(`.${styles.footerForm}`).reset();
                    emailInput.value = 'Your email has been sent';
                    setSent({sent: true});
                    setTimeout(() => {
                        emailInput.value = '';
                        setSent({sent: false});
                    }, 3000)
                }
            });
        }
    }

    return (
        <React.Fragment>
            {
                footerReady ?
                (
                    <footer>
                        <div className={styles.mainFooter}>
                            <div className={styles.footerLogoHolder}>
                                <img src={footerReady.data.logo.url} alt={footerReady.data.logo.alt} />
                                <p>{footerReady.data.slogan[0].text}</p>
                            </div>
                            <div className={styles.firstNav}>
                                <h3>{footerReady.data.quick_links_text[0].text}</h3>
                                <nav>
                                    <Link to="/" onClick={removeFooter}>Home</Link>
                                    <Link to="/advertisers" onClick={removeFooter}>For Advertisers</Link>
                                    <Link to="/retailers" onClick={removeFooter}>For Retailers</Link>
                                    <a href="https://app.wall-i.net/" target="_blank" rel="noreferrer">Login</a>
                                    <Link to="/signup">Sign Up</Link>
                                    <Link to="/">Privacy Policy</Link>
                                </nav>
                            </div>
                            <div className={styles.secondNav}>
                                <h3>{footerReady.data.follow_the_crowd_title[0].text}</h3>
                                <nav>
                                    {
                                        footerReady.data.social_networks.map(network => <a target="_blank" rel="noreferrer" href={network.network_link.url}>{network.network}</a>)
                                    }
                                </nav>
                            </div>
                            <div className={styles.contacts}>
                                <h3>{footerReady.data.learn_more_title[0].text}</h3>
                                <p>{footerReady.data.learn_more_description[0].text}</p>
                                <form className={styles.footerForm}>
                                    <ReCaptcha
                                        sitekey="6LczMVQbAAAAAHC2CWdERFoe_lvW4u_WawuGcrcB"
                                        action='footer_validation'
                                    />
                                    <input type="email" placeholder="Your email adress" name="emailSubscribe" onKeyPress={subscribe} />
                                    <div>
                                        {sent.sent ? <div className={styles.sent}><Tick /></div> : <input type="button" value="Ok" name="submit" onClick={subscribe} />}
                                    </div>
                                </form>
                                <div className={styles.notice}>
                                    <RichText render={footerReady.data.gdpr_notice} />
                                </div>
                                <div className={styles.contact}>
                                    <h5>{footerReady.data.city[0].text}</h5>
                                    <p>{footerReady.data.address[0].text}</p>
                                    <a href={footerReady.data.email_address.url} title="email address" target="_blank" rel="noreferrer">{footerReady.data.email_address.url.replace('mailto:', '')}</a>
                                </div>
                            </div>
                        </div>
                        <div className={styles.copy}>
                            <p>{footerReady.data.copyright_text[0].text}</p>
                        </div>
                    </footer>
                ) : null
            }
        </React.Fragment>
    );
}

export default Footer;