import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { Link } from 'react-router-dom';
import { client } from '../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs';
import Lottie from 'react-lottie';

import styles from './Login.module.scss';

import {ReactComponent as Next} from '../../assets/svg/next.svg';

const Login = () => {
    const [prismicData, setPrismicData] = useState({login: null});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const login = await client.query([
                    Prismic.Predicates.at('document.type', 'login__signup')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({login: login.results[0]});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const loginReady = prismicData.login;

    return (
        <React.Fragment>
            {
                loginReady ?
                (
                    <div className={styles.wrapper}>
                        <div>
                            <h1>{loginReady.data.login_title[0].text}</h1>
                            <p>{loginReady.data.login_description[0].text}</p>
                            <form>
                                <input type="email" name="email" placeholder="Type here" />
                                <div className={styles.submitHolder}>
                                    <Next />
                                    <p>press <span>Enter</span></p>
                                </div>
                            </form>
                        </div>
                        <div>
                            <Lottie options={
                                {
                                    loop: true,
                                    autoplay: true, 
                                    path: loginReady.data.lottie.url,
                                    rendererSettings: {}
                                }}
                                height={490}
                                width={660} />
                        </div>
                    </div>
                ) : null
            }
        </React.Fragment>
    );
}

export default Login;