import React, {useEffect, useState, useRef} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import Lottie from 'react-lottie';
import {RichText} from 'prismic-reactjs';
import gsap from 'gsap';
import SplitText from "gsap/SplitText";
import ScrollTrigger from 'gsap/ScrollTrigger';

import styles from './Retailers.module.scss';

import TextImage from '../../components/UI/TextImage/TextImage';
import Button from '../../components/UI/Button/Button';
import Pricing from '../../components/UI/Pricing/Pricing';
import {ReactComponent as Next} from '../../assets/svg/next.svg';

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const Retailers = () => {
    const [prismicData, setPrismicData] = useState({retailers: null, segments: null, pricing: null});

    useEffect(() => {
        window.scrollTo(0,0)
        const fetchPrismicData = async () => {
            try {
                const retailers = await client.query([
                    Prismic.Predicates.at('document.type', 'retailers_page')
                ], 
                { orderings : '[document.first_publication_date]' });

                const segments = await client.query([
                    Prismic.Predicates.at('document.type', 'advertising_networks'),
                    Prismic.Predicates.at('my.advertising_networks.is_a_market_segment', true)
                ], 
                { orderings : '[document.first_publication_date]' });

                const pricing = await client.query([
                    Prismic.Predicates.at('document.type', 'pricing'),
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({retailers: retailers.results[0], segments: segments.results, pricing: pricing.results});

                setupGSAP();
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const retailersReady = prismicData.retailers;
    const segmentsReady = prismicData.segments;
    const pricingReady = prismicData.pricing;

    let indexSelected = 0;

    const moveBack = (e) =>{
        let els = document.querySelectorAll(`.${styles.solutionsHolder} > div.${styles.solution}`);
        els[indexSelected].classList.remove(styles.active);
        indexSelected--;
        els[indexSelected].classList.add(styles.active);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected) * 100}% - ${(indexSelected) * 1.9}rem))` : `translate(calc(-${(indexSelected) * 29.2}rem))`;
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = 'translate(0)'
            }
        });
        if(indexSelected === 0) document.querySelector(`.${styles.prev}`).classList.remove(styles.show)
        document.querySelector(`.${styles.next}`).classList.remove(styles.hide)
    }

    const moveForward = (e) =>{
        let els = document.querySelectorAll(`.${styles.solutionsHolder} > div.${styles.solution}`);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected + 1) * 100}% - ${(indexSelected + 1) * 1.9}rem))` : `translate(calc(-${(indexSelected + 1) * 29.2}rem))`;
        let rem2 = window.innerWidth <= 480 ? 'translate(calc(-100% - 1.9rem))' : 'translate(-29.2rem)';
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = rem2
            }
        });
        els[indexSelected].classList.remove(styles.active);
        indexSelected++;
        els[indexSelected].classList.add(styles.active);
        if(indexSelected + 3 === els.length) document.querySelector(`.${styles.next}`).classList.add(styles.hide)
        document.querySelector(`.${styles.prev}`).classList.add(styles.show)
    }

    const openTab = e =>{
        e.target.closest(`.${styles.solution}`).classList.toggle(styles.open);
    }

    const letsTalk = e => {
        window.scrollTo({top: document.querySelector(`.${styles.pricing}`).offsetTop, behavior: 'smooth'});
    }

    const setupGSAP = () => {
        let h1 = new SplitText("h1", {type:"lines, words"});
        gsap.fromTo(h1.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3})

        let allH2 = document.querySelectorAll('h2');
        allH2.forEach((el) => {
            let h2 = new SplitText(el, {type:"lines, words"});
            gsap.fromTo(h2.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3, scrollTrigger: {
                trigger: el,
                start: "top-=450 top",
                once: true
            }})
        })

        let allH3 = document.querySelectorAll('h3');
        allH3.forEach((el) => {
            let h3 = new SplitText(el, {type:"lines, words"});
            gsap.fromTo(h3.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3, scrollTrigger: {
                trigger: el,
                start: "top-=500 top",
                once: true
            }})
        })

        let allP = document.querySelectorAll(`body #root > :not(.${styles.above}) p`);
        allP.forEach((el) => {
            gsap.fromTo(el, {opacity: 0, autoAlpha: 0}, { duration: 0.6, opacity: 1, autoAlpha: 1, delay: .3,  scrollTrigger: {
                trigger: el,
                start: "top-=800 top",
                once: true
            }})
        })

        let allImg = document.querySelectorAll(`section > img`);
        allImg.forEach((el) => {
            gsap.fromTo(el, {y: "-20%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
                trigger: el,
                start: "top-=550 top",
                once: true
            }});
        })

        let allLi = document.querySelectorAll(`section > div > ul li`);
        allLi.forEach((el) => {
            gsap.fromTo(el, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
                trigger: el,
                start: "top-=450 top",
                once: true
            }});
        })

        let allLiSolutions = document.querySelectorAll(`section > div > div > ul li`);
        allLiSolutions.forEach((el) => {
            gsap.fromTo(el, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
                trigger: el,
                start: "top-=550 top",
                once: true
            }});
        })

        gsap.fromTo(`.${styles.stepsHolder} div`, {y: "-30%", opacity: 0, autoAlpha: 0}, { duration: 0.6, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.stepsHolder}`,
            start: "top-=550 top",
            once: true
        }});

        gsap.fromTo(`.${styles.solutionsHolder} .${styles.solution}`, {x: "-50%", opacity: 0, autoAlpha: 0}, { duration: 1, x:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, scrollTrigger: {
            trigger: `.${styles.solutionsHolder}`,
            start: "top-=650 top",
            once: true
        }});

        gsap.fromTo(`.${styles.goalsHolder} div`, {y: "-30%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.goalsHolder}`,
            start: "top-=550 top",
            once: true
        }});

        gsap.fromTo(`.${styles.playersHolder} div`, {y: "-20%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.playersHolder}`,
            start: "top-=550 top",
            once: true
        }});

        gsap.fromTo(`.${styles.pricingHolder} > div`, {x: "-20%", opacity: 0, autoAlpha: 0}, { duration: 0.8, x:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.pricingHolder}`,
            start: "top-=350 top",
            once: true
        }});

        setTimeout(() => {
            gsap.to(`footer`, { duration: 0.8, opacity: 1, autoAlpha: 1, scrollTrigger: {
                trigger: `footer`,
                start: "top-=450 top",
                once: true
            }});
        }, 500);
    }

    return (
        <React.Fragment>
            {
                retailersReady ?
                (
                    <div className={styles.retailersPage}>
                        <RichText render={retailersReady.data.title1} />
                        {retailersReady.data.text_image_sections.map((section, index) => {
                            return <TextImage odd={index % 2 === 1} image={section.image} title={section.title2[0].text} excerpt={section.description1[0].text} list={section.topics} />
                        })}
                        <section className={styles.pricing}>
                            <div className={styles.titleHolder}>
                                <h2>{retailersReady.data.pricing_main_title[0].text}</h2>
                                <div className={styles.title}><h3>{retailersReady.data.pricing_title[0].text}</h3></div>
                            </div>
                            <div className={styles.pricingHolder}>
                                {
                                    pricingReady ?
                                    pricingReady.map((pricing) => <Pricing priceData={pricing.data} />) : null
                                }
                            </div>
                        </section>
                        <section className={styles.players}>
                            <div className={styles.title}><h3>{retailersReady.data.compatible_players_title[0].text}</h3></div>
                            <h2>{retailersReady.data.compatible_players_main_title[0].text}</h2>
                            <div className={styles.playersHolder}>
                                {
                                    retailersReady.data.compatible_players.map((player) => {
                                        return (
                                            <div>
                                                <Lottie options={
                                                    {
                                                        loop: true,
                                                        autoplay: true, 
                                                        path: player.lottie_json.url,
                                                        rendererSettings: {}
                                                    }}
                                                    height={289}
                                                    width={213} />
                                                <h4>{player.title2[0].text}</h4>
                                                <p>{player.description1[0].text}</p>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </section>
                        <section className={styles.solutions}>
                            <div className={styles.title}><h3>{retailersReady.data.solutions_title[0].text}</h3></div>
                            <h2>{retailersReady.data.solutions_main_title[0].text}</h2>
                            <div className={styles.solutionsHolder}>
                                {
                                    segmentsReady ?
                                    segmentsReady.map((segment) => {
                                        return (
                                            <div className={styles.solution}>
                                                <img src={segment.data.background_image.url} alt={segment.data.background_image.alt} />
                                                <h4 onClick={openTab}>{segment.data.network_title[0].text}</h4>
                                                <ul>
                                                    {
                                                        segment.data.topics.map((topic) => <li>{topic.network_topic[0].text}</li>)
                                                    }
                                                </ul>
                                            </div>
                                        );
                                    }) : null
                                }
                                <div className={styles.prev} onClick={moveBack}><Next /></div>
                                <div className={styles.next} onClick={moveForward}><Next /></div>
                            </div>
                            <Button action={letsTalk} text={retailersReady.data.solutions_button_text[0].text} />
                        </section>
                        <section className={styles.goals}>
                            <div className={styles.title}>
                                <h3>{retailersReady.data.goals_title[0].text}</h3>
                            </div>
                            <div className={styles.textHolder}>
                                <h2>{retailersReady.data.goals_main_title[0].text}</h2>
                                <Button to='signup' text={retailersReady.data.goals_button_text[0].text} />
                                <div className={styles.goalsHolder}>
                                    {
                                        retailersReady.data.goals_topics.map((goal) => {
                                            return (
                                                <div>
                                                    <h4>{goal.title2[0].text}</h4>
                                                    <p>{goal.description1[0].text}</p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </section>
                        <section className={styles.steps}>
                            <div className={styles.title}><h3>{retailersReady.data.how_it_works_title[0].text}</h3></div>
                            <h2>{retailersReady.data.how_it_works_main_title[0].text}</h2>
                            <div className={styles.stepsHolder}>
                                {
                                    retailersReady.data.steps.map((step) => {
                                        return (
                                            <div>
                                                <img src={step.icon.url} alt={step.icon.alt} />
                                                <div>
                                                    <h4>{step.title2[0].text}</h4>
                                                    <p>{step.description1[0].text}</p>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </section>
                    </div>
                ) : null
            }
        </React.Fragment>
    );
}

export default Retailers;