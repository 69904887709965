import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import Lottie from 'react-lottie';
import {Link} from 'react-router-dom';

import styles from './Signup.module.scss';

import {ReactComponent as Next} from '../../assets/svg/next.svg';
import {ReactComponent as Tick} from '../../assets/svg/tick_white.svg';

const Signup = () => {
    const [prismicData, setPrismicData] = useState({login: null});
    const [sent, setSent] = useState({sent: false});

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const login = await client.query([
                    Prismic.Predicates.at('document.type', 'login__signup')
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({login: login.results[0]});
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const subscribe = e => {
        if((e.type === 'keypress' && e.key === 'Enter') || e.type === 'click'){
            e.preventDefault();
            const emailInput = document.querySelector('input[name="email"]');
            const targetInput = document.querySelector('input[name="target"]:checked');

            const options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: emailInput.value,
                    retailer: targetInput.value === 'retailer' ? 'Yes' : 'No',
                    advertiser: targetInput.value === 'advertiser' ? 'Yes' : 'No',
                    simple: true
                }),
            };
                
            fetch('https://wall-i-be.herokuapp.com/upload', options).then((response) => {
                if(response.status === 200){
                    document.querySelector(`.${styles.simpleForm}`).reset();
                    document.querySelector(`.${styles.submitHolder} p`).style.opacity = 0;
                    emailInput.value = 'Your email has been sent';
                    setSent({sent: true});
                    setTimeout(() => {
                        emailInput.value = '';
                        setSent({sent: false});
                        document.querySelector(`.${styles.submitHolder} p`).style.opacity = 1;
                    }, 3000)
                }
            });
        }
    }

    const loginReady = prismicData.login;

    return (
        <React.Fragment>
            {
                loginReady ?
                (
                    <div className={styles.wrapper}>
                        <div className={styles.logo}>
                            <img src={loginReady.data.logo.url} alt={loginReady.data.logo.alt} />
                        </div>
                        <div>
                            <Link to='/'>Go Back</Link>
                            <h1>{loginReady.data.signup_title[0].text.split(' ').map((word, index) => index < 2 ? `${word} ` : <span>{word} </span>)}</h1>
                            <p>{loginReady.data.signup_description[0].text}</p>
                            <form className={styles.simpleForm}>
                                <div className={styles.radio}>
                                    <input type="radio" id="retailer" name="target" value="retailer" defaultChecked />
                                    <label htmlFor="retailer">I’m a Retailer</label>
                                    <input type="radio" id="advertiser" name="target" value="advertiser" />
                                    <label htmlFor="advertiser">I’m an Advertiser</label>
                                </div>
                                <div className={styles.inputSection}>
                                    <input type="email" name="email" placeholder="Your email address" onKeyPress={subscribe} />
                                    <div className={styles.submitHolder}>
                                        <div>
                                            {sent.sent ? <div className={styles.sent}><Tick /></div> : <Next onClick={subscribe} />}
                                        </div>
                                        <p>press <span>Enter</span></p>
                                    </div>
                                </div>
                            </form>
                            <p className={styles.notice}>{loginReady.data.signup_gdpr_notice[0].text}</p>
                        </div>
                        <div>
                            <Lottie options={
                                {
                                    loop: true,
                                    autoplay: true, 
                                    path: loginReady.data.lottie.url,
                                    rendererSettings: {}
                                }}
                                height={490}
                                width={660} />
                        </div>
                    </div>
                ) : null
            }
        </React.Fragment>
    );
}

export default Signup;