import React from 'react';
import {RichText} from 'prismic-reactjs';

import styles from './Pricing.module.scss';

import {ReactComponent as Info} from '../../../assets/svg/info.svg';
import {ReactComponent as InfoWhite} from '../../../assets/svg/info_white.svg';

import Button from '../Button/Button';

const Pricing = ({priceData}) => {
    const showInfo = (e) =>{
        e.target.closest(`.${styles.textHolder}`).querySelector(`.${styles.infoHolder}`).style.zIndex = 31;
        e.target.closest(`.${styles.textHolder}`).querySelector(`.${styles.infoHolder}`).style.opacity = 1;
        e.target.closest(`.${styles.textHolder}`).querySelector('div:first-child').classList.add(styles.hover);
    }

    const hideInfo = (e) =>{
        e.target.closest(`.${styles.textHolder}`).querySelector(`.${styles.infoHolder}`).style.zIndex = -1;
        e.target.closest(`.${styles.textHolder}`).querySelector(`.${styles.infoHolder}`).style.opacity = 0;
        e.target.closest(`.${styles.textHolder}`).querySelector('div:first-child').classList.remove(styles.hover);
    }

    const selectAddOn = (e) => {
        e.target.closest(`.${styles.radio}`).firstElementChild.classList.toggle(styles.active)
    }

    const showPrice = (e) => {
        if(!e.target.classList.contains(styles.active)){
            let el = e.target.parentElement;
            let parent = el.parentElement;
            if (!el.classList.contains(styles.annual)){
                e.target.parentElement.classList.add(styles.annual)
                parent.querySelector(`.${styles.active}`).classList.remove(styles.active)
                e.target.classList.add(styles.active)
                parent.querySelector(`.${styles.price}`).classList.remove(styles.show)
                parent.querySelector(`.${styles.annualPrice}`).classList.add(styles.show)
            } else{
                e.target.classList.remove(styles.active)
                parent.querySelector(`.${styles.active}`).classList.remove(styles.active)
                e.target.classList.add(styles.active)
                parent.querySelector(`.${styles.price}`).classList.add(styles.show)
                parent.querySelector(`.${styles.annualPrice}`).classList.remove(styles.show)
                e.target.parentElement.classList.remove(styles.annual)
            }
        }
    }

    const openTab = e =>{
        e.target.closest(`.${styles.priceColumn}`).querySelector(`.${styles.pricingContentHolder}`).classList.toggle(styles.open);
        e.target.closest(`.${styles.priceColumn}`).querySelector('h4').classList.toggle(styles.open);
    }

    const sendEmail = message => {
        var email = 'monteiro.mr.vitor@gmail.com';
        var subject = 'Nova lead Wall-i';
        var emailBody = `
        Olá, estou interessado no vosso serviço para Retailers, \n\n
            Pack: \n
            ${message.pack} \n
            ${message.monthly} \n\n
            Addons: \n
            Wall-i TV Box / ${message.tvBox} \n
            Wall-i Front desk tablet / ${message.frontDesk}
        `;
        window.location = "mailto:"+email+"?subject="+subject+"&body="+encodeURIComponent(emailBody);
    }

    const subscribe = e =>{
        let mainEl = e.target.closest(`.${styles.priceColumn}`);
        let tvBox, frontDesk = 'No';
        mainEl.querySelectorAll(`.${styles.radio} .${styles.active}`).forEach(element => {
            let text = element.parentElement.parentElement.querySelector(`.${styles.textHolder} > div p`).innerHTML;
            if(text.includes('TV Box')){
                tvBox = 'Yes';
            } else if(text.includes('Frontdesk')){
                frontDesk = 'Yes';
            }
        });

        const options = {
            body: {
                pack: mainEl.querySelector('h4').innerHTML,
                monthly: mainEl.querySelector(`.${styles.priceHolder} .${styles.active}`).innerHTML,
                tvBox: tvBox,
                frontDesk: frontDesk,
                pricing: true
            }
        };

        sendEmail(options.body);
    }

    return (
        <div className={`${styles.priceColumn} ${priceData.most_popular ? styles.popular : null}`}>
            <div>
                <h4 onClick={openTab}>{priceData.pricing_name[0].text}</h4>
                <div className={styles.perScreen}>
                    <p>{priceData.price_subtitle[0].text}</p>
                    {priceData.price_subtitle_extra.length ? <p className={styles.extra}>{priceData.price_subtitle_extra[0].text}</p> : null}
                </div>
                <div className={styles.priceHolder}>
                    {
                        priceData.price ?
                            priceData.annual_info_only ?
                            (
                                <div>
                                    <p className={`${styles.price} ${styles.show}`}>€<span>{priceData.price}</span>.00</p>
                                    <div className={`${styles.tabsHolder} ${styles.special}`}>
                                        <div className={styles.active}>Annually: €{priceData.annual_price}.00</div>
                                    </div>
                                </div>
                            ) : 
                            (
                                <div>
                                    <p className={`${styles.price} ${styles.show}`}>€<span>{priceData.price}</span>.00</p>
                                    <p className={styles.annualPrice}>€<span>{priceData.annual_price}</span>.00</p>
                                    <div>
                                        <div className={styles.active} onClick={showPrice}>Montlhy</div>
                                        <div className={styles.tab} onClick={showPrice}>Annual</div>
                                    </div>
                                </div>
                            ) :
                            priceData.more_info_text.length ? 
                            <div>
                                <img src={priceData.more_info_icon.url} alt={priceData.more_info_icon.alt} />
                                <p>{priceData.more_info_text[0].text}</p>
                            </div>
                        : null
                    }
                </div>
                <div className={styles.pricingContentHolder}>
                    <div className={styles.featuresHolder}>
                        <p className={styles.pricingFeaturesTitle}>{priceData.features_title[0].text}</p>
                        <ul>
                            {priceData.features.map((feature) => <li>{feature.features_topic[0].text}</li>)}
                        </ul>
                    </div>
                    <div className={styles.addonsHolder}>
                        {
                            priceData.addons_title.length ?
                            (
                                <React.Fragment>
                                    <p className={styles.pricingFeaturesTitle}>{priceData.addons_title[0].text}</p>
                                    <div className={styles.addonsRealHolder}>
                                        {
                                            priceData.addons.map((addon) => {
                                                return (
                                                    <div className={styles.addon}>
                                                        <div className={styles.radio}>
                                                            <div onClick={selectAddOn}>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.textHolder}>
                                                            <div>
                                                                <p>{addon.addon_title[0].text}</p>
                                                                <div onMouseEnter={showInfo} onMouseLeave={hideInfo}>{priceData.most_popular ? <InfoWhite /> : <Info />}</div>
                                                            </div>
                                                            <p>{addon.addon_description[0].text}</p>
                                                            <div className={styles.infoHolder}>
                                                                <RichText render={addon.addon_info} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </React.Fragment>
                            ) : null
                        }
                    </div>
                    <div>
                        <Button text={priceData.button_text[0].text} action={subscribe} />
                    </div>
                </div>
            </div>
            <div>
                <Button text={priceData.button_text[0].text} action={subscribe} />
            </div>
        </div>
    );
}

export default Pricing;