import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import { client } from '../../utils/prismicHelpers';
import {RichText} from 'prismic-reactjs';
import gsap from 'gsap';
import SplitText from "gsap/SplitText";
import ScrollTrigger from 'gsap/ScrollTrigger';
import isEmail from 'validator/lib/isEmail';

import styles from './Advertisers.module.scss';

import TextImage from '../../components/UI/TextImage/TextImage';
import Button from '../../components/UI/Button/Button';
import {ReactComponent as Next} from '../../assets/svg/next.svg';
import {ReactComponent as Tick} from '../../assets/svg/tick_white.svg';

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const Advertisers = () => {
    const [prismicData, setPrismicData] = useState({advertisers: null, segments: null});
    const [sent, setSent] = useState({sent: false});

    useEffect(() => {
        window.scrollTo(0,0)
        const fetchPrismicData = async () => {
            try {
                const advertisers = await client.query([
                    Prismic.Predicates.at('document.type', 'advertisers_page')
                ], 
                { orderings : '[document.first_publication_date]' });

                const segments = await client.query([
                    Prismic.Predicates.at('document.type', 'advertising_networks'),
                    Prismic.Predicates.at('my.advertising_networks.is_a_market_segment', false)
                ], 
                { orderings : '[document.first_publication_date]' });
                
                setPrismicData({advertisers: advertisers.results[0], segments: segments.results});

                setupGSAP();
            } catch (error) {
                console.error(error);
            }
        }

        fetchPrismicData();
    }, []);

    const advertisersReady = prismicData.advertisers;
    const segmentsReady = prismicData.segments;

    const setupGSAP = () => {
        let h1 = new SplitText("h1", {type:"lines, words"});
        gsap.fromTo(h1.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3})

        let allH2 = document.querySelectorAll('h2');
        allH2.forEach((el) => {
            let h2 = new SplitText(el, {type:"lines, words"});
            gsap.fromTo(h2.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3, scrollTrigger: {
                trigger: el,
                start: "top-=350 top",
                once: true
            }})
        })

        let allH3 = document.querySelectorAll('h3');
        allH3.forEach((el) => {
            let h3 = new SplitText(el, {type:"lines, words"});
            gsap.fromTo(h3.words, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, delay: .3, scrollTrigger: {
                trigger: el,
                start: "top-=400 top",
                once: true
            }})
        })

        let allP = document.querySelectorAll(`body #root > :not(.${styles.above}) p`);
        allP.forEach((el) => {
            gsap.fromTo(el, {opacity: 0, autoAlpha: 0}, { duration: 0.6, opacity: 1, autoAlpha: 1, delay: .3,  scrollTrigger: {
                trigger: el,
                start: "top-=650 top",
                once: true
            }})
        })

        let allImg = document.querySelectorAll(`section > img`);
        allImg.forEach((el) => {
            gsap.fromTo(el, {y: "-20%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
                trigger: el,
                start: "top-=450 top",
                once: true
            }});
        })

        let allLi = document.querySelectorAll(`section > div > ul li`);
        allLi.forEach((el) => {
            gsap.fromTo(el, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
                trigger: el,
                start: "top-=450 top",
                once: true
            }});
        })

        let allLiSolutions = document.querySelectorAll(`section > div > div > ul li`);
        allLiSolutions.forEach((el) => {
            gsap.fromTo(el, {y: "-100%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
                trigger: el,
                start: "top-=550 top",
                once: true
            }});
        })

        gsap.fromTo(`.${styles.stepsHolder} div`, {y: "-30%", opacity: 0, autoAlpha: 0}, { duration: 0.6, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.stepsHolder}`,
            start: "top-=500 top",
            once: true
        }});

        gsap.fromTo(`.${styles.solutionsHolder} .${styles.solution}`, {x: "-50%", opacity: 0, autoAlpha: 0}, { duration: 1, x:"0%", opacity: 1, autoAlpha: 1, stagger: 0.1, scrollTrigger: {
            trigger: `.${styles.solutionsHolder}`,
            start: "top-=600 top",
            once: true
        }});

        gsap.fromTo(`.${styles.goalsHolder} div`, {y: "-30%", opacity: 0, autoAlpha: 0}, { duration: 0.8, y:"0%", opacity: 1, autoAlpha: 1, stagger: 0.2, scrollTrigger: {
            trigger: `.${styles.goalsHolder}`,
            start: "top-=500 top",
            once: true
        }});

        setTimeout(() => {
            gsap.to(`footer`, { duration: 0.8, opacity: 1, autoAlpha: 1, scrollTrigger: {
                trigger: `footer`,
                start: "top-=450 top",
                once: true
            }});
        }, 500);
    }

    let indexSelected = 0;

    const moveBack = (e) =>{
        let els = document.querySelectorAll(`.${styles.solutionsHolder} > div.${styles.solution}`);
        els[indexSelected].classList.remove(styles.active);
        indexSelected--;
        els[indexSelected].classList.add(styles.active);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected) * 100}% - ${(indexSelected) * 1.9}rem))` : `translate(calc(-${(indexSelected) * 29.2}rem))`;
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = 'translate(0)'
            }
        });
        if(indexSelected === 0) document.querySelector(`.${styles.prev}`).classList.remove(styles.show)
        document.querySelector(`.${styles.next}`).classList.remove(styles.hide)
    }

    const moveForward = (e) =>{
        let els = document.querySelectorAll(`.${styles.solutionsHolder} > div.${styles.solution}`);
        let rem = window.innerWidth <= 480 ? `translate(calc(-${(indexSelected + 1) * 100}% - ${(indexSelected + 1) * 1.9}rem))` : `translate(calc(-${(indexSelected + 1) * 29.2}rem))`;
        let rem2 = window.innerWidth <= 480 ? 'translate(calc(-100% - 1.9rem))' : 'translate(-29.2rem)';
        els.forEach(el => {
            if(indexSelected > 0){
                el.style.transform = rem
            } else{
                el.style.transform = rem2
            }
        });
        els[indexSelected].classList.remove(styles.active);
        indexSelected++;
        els[indexSelected].classList.add(styles.active);
        if(indexSelected + 3 === els.length) document.querySelector(`.${styles.next}`).classList.add(styles.hide)
        document.querySelector(`.${styles.prev}`).classList.add(styles.show)
    }

    const nextStep = e => {
        if((e.type === 'keypress' && e.key === 'Enter') || e.type === 'click'){
            let els = document.querySelectorAll(`.${styles.formStep}`);
            let i;
            let length = els.length;
            els.forEach((el, index) => {
                if(el.classList.contains(styles.active)) {
                    if(el.querySelector('input').value){
                        i = index;
                    } else{
                        return;
                    }
                }
            })
            if(i + 1 === length - 1){
                const nameInput = document.querySelector('input[name="name"]');
                const emailInput = document.querySelector('input[name="email"]');
                const budgetInput = document.querySelector('input[name="budget"]');
                const genderSegmentationInput = document.querySelector('input[name="genderSegmentation"]:checked');
                const advertiseGasInput = document.querySelector('input[name="advertiseGas"]:checked');
                const advertiseRestaurantsInput = document.querySelector('input[name="advertiseRestaurants"]:checked');
                const advertiseRetailInput = document.querySelector('input[name="advertiseRetail"]:checked');
                const advertiseHotelsInput = document.querySelector('input[name="advertiseHotels"]:checked');
                const advertiseBankingInput = document.querySelector('input[name="advertiseBanking"]:checked');
                const advertiseTransportInput = document.querySelector('input[name="advertiseTransport"]:checked');
                const advertiseShoppingInput = document.querySelector('input[name="advertiseShopping"]:checked');
                const advertiseOtherInput = document.querySelector('input[name="advertiseOther"]:checked');
                const cityInput = document.querySelector('input[name="city"]');

                const advertise = [];
                if(advertiseGasInput) advertise.push(advertiseGasInput.value);
                if(advertiseRestaurantsInput) advertise.push(advertiseRestaurantsInput.value);
                if(advertiseRetailInput) advertise.push(advertiseRetailInput.value);
                if(advertiseHotelsInput) advertise.push(advertiseHotelsInput.value);
                if(advertiseBankingInput) advertise.push(advertiseBankingInput.value);
                if(advertiseTransportInput) advertise.push(advertiseTransportInput.value);
                if(advertiseShoppingInput) advertise.push(advertiseShoppingInput.value);
                if(advertiseOtherInput) advertise.push(advertiseOtherInput.value);

                const options = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: nameInput.value,
                        email: emailInput.value,
                        budget: budgetInput.value,
                        genderSegmentation: genderSegmentationInput.value,
                        advertise: advertise,
                        city: cityInput.value
                    }),
                };
                  
                fetch('https://wall-i-be.herokuapp.com/upload', options).then((response) => {
                    if(response.status === 200){
                        document.querySelector(`.${styles.progressIndicator} span`).innerHTML = 100;
                        let style = document.querySelector(`:root`).style;
                        style.setProperty('--barwidth', '100%');
                        els[i].classList.add(styles.after)
                        els[i + 1].classList.add(styles.active)
                        setTimeout(() => {
                            els[i].classList.remove(styles.active)
                        }, 400);
                        setSent({sent: true});
                        setTimeout(() => {
                            document.querySelector('form').reset();
                            document.querySelector(`.${styles.progressIndicator} span`).innerHTML = 0;
                            let style = document.querySelector(`:root`).style;
                            style.setProperty('--barwidth', Math.round(0 + '%'));
                            els[i + 1].classList.remove(styles.active)
                            setTimeout(() => {
                                els.forEach((el) => {
                                    el.classList.remove(styles.after)
                                })
                                els[0].classList.add(styles.active);
                            }, 400);
                            setSent({sent: false});
                        }, 3000)
                    }
                });

                return;
            }
            if(i + 1 < length){
                if(i === 1){
                    const emailInput = document.querySelector('input[name="email"]');
                    if(!isEmail(emailInput.value)){
                        return;
                    }
                }
                if(i + 1 === 4 && window.outerWidth <= 480){
                    document.querySelector('form').style.height = '385px';
                } else if(window.outerWidth <= 480){
                    document.querySelector('form').style.height = '180px';
                }

                if(i + 1 === 4 && window.outerWidth > 480){
                    document.querySelector('form').style.height = '220px';
                } else if(window.outerWidth > 480){
                    document.querySelector('form').style.height = '190px';
                }
                els[i].classList.add(styles.after)
                els[i + 1].classList.add(styles.active)
                setTimeout(() => {
                    els[i].classList.remove(styles.active)
                }, 400);
                document.querySelector(`.${styles.progressIndicator} span`).innerHTML = Math.round(((i + 1) / (length - 1)) * 100);
                let style = document.querySelector(`:root`).style;
                style.setProperty('--barwidth', Math.round(((i + 1) / (length - 1)) * 100) + '%')
            }
        }
    }

    const openTab = e =>{
        e.target.closest(`.${styles.solution}`).classList.toggle(styles.open);
    }

    const letsTalk = e => {
        window.scrollTo({top: document.getElementById('form').offsetTop, behavior: 'smooth'});
    }

    return (
        <React.Fragment>
            {
                advertisersReady ?
                (
                    <div className={styles.advertisersPage}>
                        <RichText render={advertisersReady.data.title1} />
                        {advertisersReady.data.text_image_sections.map((section, index) => {
                            return <TextImage odd={index % 2 === 1} image={section.image} title={section.title2[0].text} excerpt={section.description1} list={section.topics} />
                        })}
                        <section className={styles.solutions}>
                            <div className={styles.title}><h3>{advertisersReady.data.solutions_title[0].text}</h3></div>
                            <h2>{advertisersReady.data.solutions_main_title[0].text}</h2>
                            <div className={styles.solutionsHolder}>
                                {
                                    segmentsReady ?
                                    segmentsReady.map((segment) => {
                                        return (
                                            <div className={`${styles.solution}`}>
                                                <img src={segment.data.background_image.url} alt={segment.data.background_image.alt} />
                                                <h4 onClick={openTab}>{segment.data.network_title[0].text}</h4>
                                                <ul>
                                                    {
                                                        segment.data.topics.map((topic) => <li>{topic.network_topic[0].text}</li>)
                                                    }
                                                </ul>
                                            </div>
                                        );
                                    }) : null
                                }
                                <div className={styles.prev} onClick={moveBack}><Next /></div>
                                <div className={styles.next} onClick={moveForward}><Next /></div>
                            </div>
                            <Button action={letsTalk} text={advertisersReady.data.solutions_button_text[0].text} />
                        </section>
                        <section className={styles.goals}>
                            <div className={styles.title}>
                                <h3>{advertisersReady.data.goals_title[0].text}</h3>
                            </div>
                            <div className={styles.textHolder}>
                                <h2>{advertisersReady.data.goals_main_title[0].text}</h2>
                                <div className={styles.goalsHolder}>
                                    {
                                        advertisersReady.data.numbers.map((number) => {
                                            return (
                                                <div>
                                                    <h4>{number.key_takeway[0].text}</h4>
                                                    <p>{number.key_takeway_title[0].text}</p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </section>
                        <section className={styles.steps}>
                            <div className={styles.title}><h3>{advertisersReady.data.how_it_works_title[0].text}</h3></div>
                            <h2>{advertisersReady.data.how_it_works_main_title[0].text}</h2>
                            <div className={styles.stepsHolder}>
                                {
                                    advertisersReady.data.steps.map((step) => {
                                        return (
                                            <div>
                                                <img src={step.icon.url} alt={step.icon.alt} />
                                                <div>
                                                    <h4>{step.title2[0].text}</h4>
                                                    <p>{step.description1[0].text}</p>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </section>
                        <section className={styles.talking} id="form">
                            <div className={styles.title}><h3>{advertisersReady.data.let_s_talk_title[0].text}</h3></div>
                            <h2>{advertisersReady.data.let_s_talk_main_title[0].text}</h2>
                            <form>
                                <div className={`${styles.formStep} ${styles.active}`}>
                                    <label htmlFor="name"><span>1.</span> Let’s start with your name</label>
                                    <input type="text" name="name" placeholder="Type here" onKeyPress={nextStep}/>
                                </div>
                                <div className={styles.formStep}>
                                    <label htmlFor="email"><span>2.</span> Were can we contact you</label>
                                    <input type="email" name="email" placeholder="Email" onKeyPress={nextStep} />
                                </div>
                                <div className={styles.formStep}>
                                    <label htmlFor="budget"><span>3.</span> What is your budget?</label>
                                    <input type="number" name="budget" placeholder="€ Budget" onKeyPress={nextStep} />
                                </div>
                                <div className={styles.formStep}>
                                    <label><span>4.</span> Do you want age/gender segmentation?</label>
                                    <div>
                                        <label className={styles.container}>Yes
                                            <input type="radio" name="genderSegmentation" value="yes" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>No
                                            <input type="radio" name="genderSegmentation" value="no" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.formStep}>
                                    <label><span>5.</span> Were do you want to advertise?</label>
                                    <div className={styles.advertisingSections}>
                                        <label className={styles.container}>Gas Stations
                                            <input type="radio" name="advertiseGas" value="Gas Stations" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>Restaurants
                                            <input type="radio" name="advertiseRestaurants" value="Restaurants" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>Retail Stores
                                            <input type="radio" name="advertiseRetail" value="Retail Stores" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>Hotels
                                            <input type="radio" name="advertiseHotels" value="Hotels" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>Banking
                                            <input type="radio" name="advertiseBanking" value="Banking" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>Public Transportation
                                            <input type="radio" name="advertiseTransport" value="Public Transportation" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>Shopping Malls
                                            <input type="radio" name="advertiseShopping" value="Shopping Malls" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                        <label className={styles.container}>Other
                                            <input type="radio" name="advertiseOther" value="Other" onKeyPress={nextStep} />
                                            <span className={styles.checkmark}></span>
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.formStep}>
                                    <label htmlFor="city"><span>6.</span> In wich city?</label>
                                    <input type="text" name="city" placeholder="Lisbon, Porto, etc..." onKeyPress={nextStep} />
                                </div>
                                <div className={styles.formStep}>
                                    <label htmlFor="city"><span>7.</span> Thank you!</label>
                                    <input type="text" name="none" value="Your request has been sent" />
                                </div>
                                <div className={styles.submitHolder}>
                                    {
                                        sent.sent ?
                                        <div className={styles.sent}><Tick /></div> :
                                        <React.Fragment>
                                            <input type="button" value="Ok" onClick={nextStep} />
                                            <p>press <span>Enter</span></p>
                                        </React.Fragment>
                                    }
                                </div>
                            </form>
                            <div className={styles.progressIndicator}>
                                <p><span>0</span>% completed</p>
                                <div className={styles.bar}></div>
                            </div>
                        </section>
                    </div>
                ) : null
            }
        </React.Fragment>
    );
}

export default Advertisers;