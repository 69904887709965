import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import './styles.scss';

import Header from './components/Navigation/Header/Header';
import Footer from './components/Navigation/Footer/Footer';
import Homepage from './containers/Homepage/Homepage';
import Login from './containers/Login/Login';
import Signup from './containers/Signup/Signup';
import Advertisers from './containers/Advertisers/Advertisers';
import Retailers from './containers/Retailers/Retailers';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/advertisers">
                    <Header />
                    <Advertisers />
                    <Footer />
                </Route>
                <Route exact path="/retailers">
                    <Header />
                    <Retailers />
                    <Footer />
                </Route>
                <Route exact path="/privacy">
                    <Header />
                    <Footer />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/signup">
                    <Signup />
                </Route>
                <Route exact path="/">
                    <Header />
                    <Homepage />
                    <Footer />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
