// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://wall-i.cdn.prismic.io/api/v2'

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = 'MC5ZSDNGX1JNQUFDQUF5dVlF.GxMwNELvv71WNu-_ve-_vXnvv73vv71Y77-9KO-_ve-_vWLvv73vv73vv71VJQLvv73vv73vv71477-9Ve-_vQ'

// -- Link resolution rules
// Manages the url links to internal Prismic documents
// export const linkResolver = (doc) => {
//   if (doc.type === 'news') return `/news/${doc.uid}`
//   return '/'
// }